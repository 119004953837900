<template>
  <div class="mb15">
        <div class='mb15 fz-16'>模块介绍设置用来配置功能模块在品牌端-应用服务中心的展示</div>
        <span class='mr15'>
          <a-button type="primary" @click="open">
            添加模块介绍
          </a-button>
        </span>
      </div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      rowKey="id"
      bordered
      :loading="loading"
      @change="handleTableChange"
    >
      <template v-slot:operation="{ record }">
        <a-button type="link" v-has:editProductModel="() => edit(record)" >修改</a-button>
      </template>
    </a-table>
       <!-- @change="selectChange" -->
    <a-modal
      v-model:visible="visible"
      :title="type=='edit'?'修改模块介绍':'添加模块介绍'"
      centered
      @ok="submit"
      destroyOnClose
      :width="700"
    >
      <a-form
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        ref="formRef"
        :model="form"
        :rules="rules"
      >
        <a-form-item label="模块：" name="key_id">
          <a-select
            placeholder="请设置模块"
            v-model:value="form.key_id"
            :disabled="type == 'edit'&&form.app_mark"
          >
            <a-select-option
              v-for="(item, index) in optionList"
              :key="index"
              :value="item.key_id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否收费：" name="price">
          <a-radio-group name="is_base" v-model:value="form.is_base">
            <a-radio :value="2">是</a-radio>
            <a-radio :value="1">否</a-radio>
          </a-radio-group>
          <a-input
            style="margin-top: 10px"
            prefix="￥"
            suffix="RMB"
            v-model:value="form.price"
            v-inputFilter:int
            v-if="form.is_base == 2"
          />
        </a-form-item>
        <a-form-item
          label="图片:"
          name="app_logo"
          help="jpg/png文件，且不超过500kb （只能上传一张）"
          style="position: relative;"
        >
          <a-upload
            v-model:fileList="fileList"
            name="file"
            accept=""
            list-type="picture-card"
            class="avatar-uploader"
            :action="baseUrl + 'upload/alone'"
            :headers="header"
            @change="handleChange"
            @preview="handlePreview"
          >
            <div v-if="fileList.length == 0">
              <PlusOutlined style="color:#999;fontSize:18px"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <!-- <div style="position:absolute;bottom:20px;right:10px;cursor: pointer;" class="active" @click="defaultImg">恢复默认图</div> -->
        </a-form-item>
        <a-form-item label="模块介绍:" name="desc" style="marginTop: 20px">
          <a-textarea v-model:value="form.desc" style="height:120px"/>
        </a-form-item>
        <a-form-item
          label="模块排序:"
          name="sort"
          help="数值越大，排序越靠前；如果数值相同，则按照添加时间由大到小排序显示"
        >
          <a-input type="number" v-model:value="form.sort"></a-input>
        </a-form-item>
        <a-form-item
          label="是否显示:"
          name="is_show"
          help="默认在品牌端显示，勾选否则不显示"
          style="marginTop: 20px"
        >
          <a-radio-group name="is_show" v-model:value="form.is_show" required >
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
</template>

<script>
import {PlusOutlined} from '@ant-design/icons-vue'
import { onMounted, ref, reactive, toRefs, h } from "vue";
import { brandAppsIndex, brandAppsInfo, brandAppsMarks,brandAppsUpdate,brandAppsCreate} from "@/api/product";
import { $iscode } from "@/utils/app";
import { message } from "ant-design-vue";
import { getToken } from "@/libs/util";
import config from "@/config";
import { method } from 'lodash';
const baseUrl = config.baseUrl[process.env.VUE_APP_type];
const oss = config.oss[process.env.VUE_APP_type];
const header = { apiAuth: getToken() };

const columns = [
  {
    title: "功能模块",
    dataIndex: "app_name",
    key: "app_name",
    width:220,
  },
  //  {
  //   title: "应用标识",
  //   dataIndex: "app_mark",
  //   key: "app_mark",
  // },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    width:180,
  },
  {
    title: "图片",
    dataIndex: "app_logo",
    key: "app_logo",
    width:140,
    align:"center",
    customRender: ({ record }) => {
      return h(
        "img",
        {
          style: {
            width: "100px",
            height:'100px',
            objectFit:"cover"
          },
          src: record.app_logo || require("@/assets/images/normal.png"),
        },
        ""
      );
    },
  },
  {
    title: "模块介绍",
    dataIndex: "desc",
    key: "desc",
  },
  {
    title: "模块排序",
    dataIndex: "sort",
    key: "sort",
    align:"center",
    width:120,
  },
  {
    title: "操作",
    key: "operation",
    slots: {
      customRender: "operation",
    },
    align:"center",
    width: 140,
  },
];

export default {
  components:{
    PlusOutlined
  },
  setup() {
    const list = ref([]);

    const formRef = ref(null);

    const validators = async (rule, value) => {
       if(state.form.is_base==2){
        if(value<=0){
            return Promise.reject("请完善收费价格！");
        }else{
          return Promise.resolve();
        }
       }else{
         return Promise.resolve();
       }
    };

    const state = reactive({
      loading: false,
      pagination: {
        //分页
        total: 0,
        current: 1,
        pageSize: 8,
      },
      visible: false,
      form: {
        key_id: "",
        is_base: 2, // 1不 2收
        app_logo: "",
        desc: "",
        sort: "",
        id: "",
        is_show: 1, //1显 0隐
        price: 0,
        app_mark: "", //id
      },
      loadingImg: false,
      type: "edit", //add
      optionList: [],
      fileList: [],
      previewVisible: false,
      previewImage: "",
      default_img:'',
      rules: {
        key_id: [
          {
            required: true,
            message: "模块名称不能为空！",
          },
        ],
        price: [
          {
            required: true,
            validator: validators,
            trigger: 'blur',
          },
        ],
        app_logo:[{ required: true, message: '请完善图片！' }],
         desc:[{ required: true, message: '模块介绍不能为空！' }],
         sort:[{ required: true, message: '模块排序不能为空！' }]
      },
    });

    

    const methods = {
      //详情
      async edit(record) {
        state.type = 'edit';
        state.visible = true;
        let res = await brandAppsInfo(record.id).then((res) => res.data); //详情
        if ($iscode(res)) {
          state.form = res.data || {};
          if(state.form.app_mark){
            state.form.key_id=Number(state.form.app_mark)
          }
          state.fileList = res.data.app_logo? [{ url: res.data.app_logo, uid: res.data.id }]: [];
          state.default_img = res.data.app_logo || require("@/assets/images/normal.png");
        } else {
          message.error(res.msg);
        }
      },
      //列表
      async getList() {
        state.loading = true;
        const { current, pageSize } = state.pagination;
        let res = await brandAppsIndex({ page: current, limit: pageSize }).then((res) => res.data);
        state.loading = false;
        if ($iscode(res)) {
          list.value = res.data.data || [];
          state.pagination.total = res.data.total || 0;
        }else{
           message.error(res.msg);
        }
      },
      handleTableChange(e) {
        state.pagination.current = e.current;
        methods.getList();
      },
      async getBrandAppsMarks() {
        let res = await brandAppsMarks(2).then((res) => res.data); //0全部 1已经添加 2未添加
        if ($iscode(res)) {
          state.optionList = res.data || [];
        } else {
          message.error(res.msg);
        }
      },
      open() {
        state.form = { //初始值
          key_id: "",
          is_base: 2, // 1不 2收
          app_logo: "",
          desc: "",
          sort: "",
          id: "",
          is_show: 1, //1显 0隐
          price: 0,
          app_mark: "", //id
        }
        state.fileList =[];
        state.type = 'add';
        methods.getBrandAppsMarks();
        state.visible = true;
      },

      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      },
      // 上传
      handleChange(info) {
        if (info.file.status === "done") {
          state.fileList = info.fileList.splice(info.fileList.length - 1, 1);
          console.log(state.fileList,'22')
          state.form.app_logo = oss + info.file.response.data.path;
        }
        if (info.file.status === "error") {
          message.error("图片上传失败！");
        }
        if (info.file.status === "removed") {
          state.form.app_logo = "";
        }
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await methods.getBase64(file.originFileObj);
        }
        state.previewImage = file.url || file.preview;
        state.previewVisible = true;
      },
      //默认图
      // defaultImg(){
      //      state.fileList = [{uid:1,url:state.default_img}]
      //      state.form.app_logo = state.default_img;
      // },
      //提交
      submit() {
        formRef.value.validate().then((data) => {
             if(state.type=='edit'){
                  methods.putUpdate()
             }else{
                methods.brandAppsCreate()
             }
          }).catch((err) => {
            message.error(err.errorFields[0].errors);
          });
      },
      //修改
     async putUpdate(){
       const hide = message.loading('正在加载中...', 0);
         state.form.price=state.form.is_base==2?state.form.price:'';
         let res = await brandAppsUpdate({...state.form,app_mark:state.form.key_id}).then(res=>res.data);
         setTimeout(hide);
         if($iscode(res)){
            state.visible = false;
            state.pagination.current = 0;
            methods.getList();
            message.success(res.msg);
         }else{
            message.error(res.msg);
         }
      },
      //添加
      async brandAppsCreate(){
        const hide = message.loading('正在加载中...', 0);
        state.form.price=state.form.is_base==2?state.form.price:'';
         let res = await brandAppsCreate({...state.form,app_mark:state.form.key_id}).then(res=>res.data);
         setTimeout(hide);
         if($iscode(res)){
            state.visible = false;
            state.pagination.current = 0;
            methods.getList();
            message.success(res.msg);
         }else{
            message.error(res.msg);
         }
       },
      //  selectChange(e){
      //    state.form.app_mark = e;
      //  }
     };

    onMounted(() => {
      methods.getList();
      methods.getBrandAppsMarks();
    });
    return {
      ...toRefs(state),
      columns,
      ...methods,
      list,
      formRef,
      baseUrl,
      oss,
      header,
    };
  },
};
</script>
<style scope>
.avatar-uploader > .ant-upload {
  width: 90px;
  height: 90px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
  
}
.active:hover{
  color: #169bd5;
}
</style>
