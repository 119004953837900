import axios from '@/utils/request'
// 列表
export const getList = function(params) {
	return axios.request({
		url: 'system/apps/payLists',
		method: 'get',
		params
	})
};
// 列表
export const newdata = function(data) {
	return axios.request({
		url: 'system/apps/newData',
		method: 'get',
		data
	})
};
// 添加模块
export const addAppPrice = function(data) {
	return axios.request({
		url: 'system/apps/addAppPrice',
		method: 'post',
		data
	})
};
// 修改模块
export const editAppPrice = function(data) {
	return axios.request({
		url: 'system/apps/updateAppPrice',
		method: 'put',
		data
	})
};
// 删除模块
export const delAppPrice = function(id) {
	return axios.request({
		url: 'system/apps/delAppPrice/'+id,
		method: 'DELETE'
	})
};



// 短信列表
export const getSms = function(data) {
	return axios.request({
		url: 'brand/smsPackage/lists',
		method: 'get',
		data
	})
};
// 添加模块
export const addSms = function(data) {
	return axios.request({
		url: 'brand/smsPackage/save',
		method: 'post',
		data
	})
};
// 修改模块
export const editSms = function(data) {
	return axios.request({
		url: 'brand/smsPackage/edit',
		method: 'POST',
		data
	})
};
// 删除模块
export const delSms = function(data) {
	return axios.request({
		url: 'brand/smsPackage/delete',
		method: 'POST',
		data
	})
};



// 服务费列表
export const getappServeFees = function(params) {
	return axios.request({
		url: 'system/appServeFees/getServeFees',
		method: 'get',
		params
	})
};


// 添加服务费
export const setappServeFees = function(data) {
	return axios.request({
		url: 'system/appServeFees/create',
		method: 'post',
		data
	})
};
export const uptappServeFees = function(data) {
	return axios.request({
		url: 'system/appServeFees/setServeFees',
		method: 'post',
		data
	})
};

// 服务费列表
export const getapplist = function(params) {
	return axios.request({
		url: 'system/appServeFees/lists',
		method: 'get',
		params
	})
};

// 删除服务费
export const delAppServeFees = function(id) {
	return axios.request({
		url: 'system/appServeFees/delete/'+id,
		method: 'DELETE'
	})
};

// 应用 - 使用记录列表
export const brandAppsLogsList = function(params) {
	return axios.request({
		url: 'system/brandApps/logs/list',
		method: 'get',
		params
	})
};

// 应用 - 使用记录列表详情
export const brandAppsLogsDetail = function(params) {
	return axios.request({
		url: 'system/brandApps/logs',
		method: 'get',
		params
	})
};

//应用列表
export const brandAppsIndex = function(params) {
	return axios.request({
		url: 'system/app/index',
		method: 'get',
		params
	})
};

//模块介绍详情
export const brandAppsInfo = function(params) {
	return axios.request({
		url: 'system/app/info/'+params,
		method: 'get',
		params
	})
};

// 应用标记列表
export const brandAppsMarks = function(id) {
	return axios.request({
		url: 'system/app/marks/'+id,
		method: 'get'
	})
};

// 更新应用模块
export const brandAppsUpdate = function(data) {
	return axios.request({
		url: 'system/app/update',
		method: 'put',
		data
	})
};

// 添加应用模块
export const brandAppsCreate = function(data) {
	return axios.request({
		url: 'system/app/create',
		method: 'post',
		data
	})
};


// 服务费列表
export const brandAppsServeList = function(params) {
	return axios.request({
		url: 'system/app_price/serve_index',
		method: 'get',
		params
	})
};

// 服务费详情
export const brandAppsServeInfo = function(params) {
	return axios.request({
		url: 'system/app_price/serve_info/'+params,
		method: 'get',
		params
	})
};



// 服务费修改价格
export const brandAppsServeUpdate = function(data) {
	return axios.request({
		url: 'system/app_price/update',
		method: 'put',
		data
	})
};

// 服务费修改年费
export const brandAppsServeUpdatePrice = function(data) {
	return axios.request({
		url: 'system/app_price/serve_update',
		method: 'put',
		data
	})
};


// 平台全包价格
export const brandAppAllPriceInfo = function(params) {
	return axios.request({
		url: 'system/app_price/app_all_price_info',
		method: 'get',
		params
	})
};

// 应用续费及开通模块列表 
export const brandAppRenewInfo = function(params) {
	return axios.request({
		url: 'sysBrand/app_renew_info',
		method: 'get',
		params
	})
};

// 应用开通
export const brandAppOpen = function(data) {
	return axios.request({
		url: 'sysBrand/app_open',
		method: 'post',
		data
	})
};


// 应用续费
export const brandAppRenew = function(data) {
	return axios.request({
		url: 'sysBrand/app_renew',
		method: 'post',
		data
	})
};


// 品牌商开户收费模式
export const brandGetAppCost = function(data) {
	return axios.request({
		url: 'sysBrand/get_app_cost',
		method: 'get',
		data
	})
};

// 价格计算
export const brandAppCountPrice = function(data) {
	return axios.request({
		url: 'sysBrand/app_count_price',
		method: 'post',
		data
	})
};

// 价格计算
export const brandGetCareateAppPrice = function(data) {
	return axios.request({
		url: 'sysBrand/get_careate_app_price',
		method: 'post',
		data
	})
};


// 应用列表
export const getAppList = function(params) {
	return axios.request({
		url: 'system/app/index',
		method: 'get',
		params
	})
};

// 付费模板开通记录
export const getCodeTemplateList = function(params) {
	return axios.request({
		url: 'product_manage/code_template/order',
		method: 'get',
		params
	})
};
// 付费模板-获取系统收费的模板
export const getCodeTemplateSysList = function(params) {
	return axios.request({
		url: 'product_manage/code_template/getSystemTemplate',
		method: 'get',
		params
	})
};
// 付费模板-开通
export const setCodeTemplate = function(data) {
	return axios.request({
		url: 'product_manage/code_template/bay',
		method: 'post',
		data
	})
};
// 付费模板-开通
export const codeTemplateDel = function(data) {
	return axios.request({
		url: 'product_manage/code_template/del',
		method: 'DELETE',
		data
	})
};